<template>
  <v-container class="">
    <v-row>
      <v-col>
        <GmapMap
          ref="mymap"
          :center="mapCenter"
          :zoom="mapZoom"
          style="width: 100%; height: 72vh"
        >
          <GmapMarker
            v-for="(item, index) in markets"
            :key="index"
            :icon="item.icon"
            :position="item.coordenadas"
            :draggable="false"
            @click="dialog = true"
          />
          <gmap-info-window
            v-for="(item, index) in markets"
            :key="index + index"
            @closeclick="true"
            :opened="true"
            :position="item.coordenadas"
            :options="{
              pixelOffset: {
                width: 0,
                height: -35,
              },
            }"
            >{{ item.info }}</gmap-info-window
          >
        </GmapMap>
      </v-col>
    </v-row>
    <!-- /*** */ -->
    <v-dialog scrollable v-model="dialog" persistent max-width="100%">
      <v-card>
        <v-card-title>Select Country</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 90%">
          <!-- /** */ -->
          <cuerpo></cuerpo>
          <!-- /** */ -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn> -->
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /*** */ -->
  </v-container>
</template>

<script>
import cuerpo from './../DashboardContent/modalInformacionDeGuiaCuerpo';
import axios from 'axios';
export default {
  components: {
    cuerpo,
  },
  data() {
    return {
      dialog: false,
      mapCenter: { lat: 15.0032599, lng: -90.4136375 },
      markets: [],
      mapTypeId: 'roadmap',
      mapZoom: 8,
    };
  },
  methods: {
    async getSnpedGoogleMapPoints(lat, lng) {
      let url = `https://roads.googleapis.com/v1/snapToRoads`;
      let data = {
        path: lat + ',' + lng,
        interpolate: true,
        key: 'AIzaSyAml7wQrQuzQWTVzzzLCBU_v1yunwNgHHE',
      };

      await axios
        .get(url, {
          params: data,
        })
        .then((response) => {
          /**
           * respuesta de todos los puntos tirados a carretera
           */
          response.data.snappedPoints.forEach((snapedPoint) => {
            this.markets.push({
              info: 'G maps',
              coordenadas: {
                lat: snapedPoint.location.latitude,
                lng: snapedPoint.location.longitude,
              },
              icon: 'http://labs.google.com/ridefinder/images/mm_20_blue.png',
            });
          });
        });
    },
    async getSnpedOSRMPoints(lat, lng) {
      let url = `http://localhost:5000/nearest/v1/driving/${lng},${lat}?number=1`;
      let data = {};

      await axios
        .get(url, {
          params: data,
        })
        .then((response) => {
          /**
           * respuesta de todos los puntos tirados a carretera
           */
          response.data.waypoints.forEach((snapedPoint) => {
            console.log(snapedPoint.location);
            this.markets.push({
              info: 'OSMR coors ' + moment(new Date()).format('HH:mm:ss'),
              coordenadas: {
                lat: snapedPoint.location[1],
                lng: snapedPoint.location[0],
              },
              icon: 'http://labs.google.com/ridefinder/images/mm_20_red.png',
            });
          });
        });
    },
  },

  mounted() {
    const { Abc } = this.$FeathersVuex.api;
    Abc.on('created', (gpsOnTrack) => {
      console.log('created');
      console.log(gpsOnTrack);
      /** */
      // this.getSnpedGoogleMapPoints(gpsOnTrack.lat, gpsOnTrack.lng);
      // this.getSnpedOSRMPoints(gpsOnTrack.lat, gpsOnTrack.lng);
      // /** */
      // this.markets.push({
      //   info: 'Cruda',
      //   icon: 'http://maps.google.com/mapfiles/kml/pal4/icon15.png',
      //   coordenadas: {
      //     lat: parseFloat(gpsOnTrack.lat),
      //     lng: parseFloat(gpsOnTrack.lng),
      //   },
      // });
    });
  },
};
</script>
