<template>
  <v-container class="">
    <v-row>
      <v-col>
        <GmapMap
          ref="mymap"
          :center="mapCenter"
          :zoom="mapZoom"
          style="width: 100%; height: 72vh"
        >
          <GmapMarker
            v-for="item in markets"
            :key="item.id"
            :icon="item.icon"
            :position="item.coordenadas"
            :draggable="false"
            @click="fnGetGuia(item.id)"
          />
        </GmapMap>
      </v-col>
    </v-row>
    <!-- /*** */ -->
    <!-- /*** */ -->
    <v-dialog scrollable v-model="dialog" persistent max-width="100%">
      <v-card>
        <v-card-title>Data kalan S.A.</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 90%">
          <!-- /** */ -->
          <cuerpo :guia="selectedGuia"></cuerpo>
          <!-- /** */ -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn> -->
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- /*** */ -->
    <!-- /*** */ -->
  </v-container>
</template>

<script>
import cuerpo from './../DashboardContent/modalInformacionDeGuiaCuerpo';
import { mapState, mapGetters, mapActions } from 'vuex';
import axios from 'axios';
export default {
  components: {
    cuerpo,
  },
  data() {
    return {
      selectedGuia: [],
      //
      dialog: false,
      mapCenter: { lat: 15.0032599, lng: -90.4136375 },
      markets: [],
      mapTypeId: 'roadmap',
      mapZoom: 8,
    };
  },
  methods: {
    ...mapActions('Gpsguias', {
      getguias: 'get',
      findguiasMapa: 'find',
      patchGuia: 'patch',
      crearGuia: 'create',
    }),
    getRequestParams() {
      /**
       * calculo de fechas para muestra de guias segun creadas
       */
      var firstDay = moment().startOf('day');
      var lastDay = moment().endOf('day');

      /**
       * configuración de parametros
       */
      const params = {};
      params['$select'] = {
        _id: 1,
        guiaReceptor: 1,
        entrega: 1,
      };
      /**
       * paramentros de búsqueda por fecha
       */

      params['quienEntrega.syncFecha'] = {
        $gte: firstDay._d,
        $lte: lastDay._d,
      };

      /**
       * parametros para busqueda de Guias
       */

      params['guiaStatus'] = 'Entregado';

      if (this.searchGuiaCodigo != '') {
        params['guiaCodigo'] = this.searchGuiaCodigo;
      }

      if (this.prop_departamento != '') {
        params[
          'guiaReceptor.guiaReceptorDepartamento'
        ] = this.prop_departamento;
      }

      if (this.prop_placa != '' && this.prop_piloto != '') {
        params['quienCarga.pilotoPlaca'] = this.prop_placa;
        params['quienCarga.pilotoNombre'] = this.prop_piloto;
      }

      /**
       * paramentros de búsqueda por paginación (obligatorios)
       */
      params['$limit'] = 700;

      /**
       * fin de parametros para búsqueda de Guias
       */

      return params;
    },
    fnGetGuia(id) {
      this.getguias(id).then((result) => {
        this.selectedGuia = result;
        this.dialog = true;
      });
    },
  },
  mounted() {
    this.findguiasMapa({
      query: this.getRequestParams(),
    }).then((result) => {
      result.data.forEach((guia) => {
        this.markets.push({
          id: guia._id,
          coordenadas: {
            lat: guia.entrega.coordenadas.coordinates[1],
            lng: guia.entrega.coordenadas.coordinates[0],
          },
          icon: 'http://labs.google.com/ridefinder/images/mm_20_green.png',
        });
      });
    });
    /**
     * ETR
     */
    const { Gpsguias } = this.$FeathersVuex.api;
    Gpsguias.on('patched', (guia) => {
      console.log('patched');
      console.log(guia);
      this.markets.push({
        id: guia._id,
        coordenadas: {
          lat: guia.entrega.coordenadas.coordinates[1],
          lng: guia.entrega.coordenadas.coordinates[0],
        },
        icon: 'http://labs.google.com/ridefinder/images/mm_20_green.png',
      });
    });
  },
};
</script>
